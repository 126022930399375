<template>
  <a-row class="ministry">
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>

      <a-row class="flex-no__wrap" type="flex">
        <a-button type="primary" @click="openCreateModal">
          <a-icon type="plus" />{{ $t('Add') }}
        </a-button>
      </a-row>
    </a-row>
    <a-table
      :key="$route.fullPath"
      :columns="columns"
      :data-source="list"
    >
      <template slot="name" slot-scope="item">
        <div
          style="cursor: pointer"
          @click="
            $router.push({
              name: 'posts',
              params: {
                menu_id: item.id,
              },
            })
          "
        >
          {{ item.name }}
        </div>
      </template>
      <template slot="count" slot-scope="item">
        <strong>{{ item.post_count }}</strong> {{ $t("count") }}
      </template>
      <template slot="operation" slot-scope="item">
        <!-- <a-icon
          class="action-btns"
          type="edit"
          @click="
            $router.push(
              { 
                name: 'post-update',
                params: { id: item.id },
                query: { menu: $route.params.id }
              },
              () => null
            )
          "
        /> -->
        <a-button type="primary" class="edit-btn" @click="editMenu(item.id)">
          {{ $t("Edit") }}
          <a-icon type="edit" class="action-btns" />
        </a-button>
        <!-- <a-popconfirm
          :cancel-text="$t('No')"
          :ok-text="$t('Yes')"
          :title="$t('APopconfirm')"
          @confirm="removeItem(item)"
        >
          <a-icon class="action-btns" style="margin: 0 10px" type="delete" />
        </a-popconfirm> -->
      </template>
    </a-table>
    <a-modal
      :visible="modalVisible"
      :title="$t('Add')"
      :closable="false"
      style="max-height: 500px; max-width: 900px"
      width="900px"
    >
      <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          Язык:
        </a-col>
        <a-col :span="12">
          <!-- lang translit tabs -->
          <a-row align="middle" class="flex-baseline flex-wrap">
            <lang-tabs v-model="langTab" class="mb-1x" />
          </a-row>
        </a-col>
      </a-row>
      <a-row align="middle" class="flex-baseline">
        <a-col :span="6" class="form_title">
          {{ $t("MenuTitle") }}:
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            @change="slugCompute($event, langTab, 'menu', formMenu.name)"
            v-model="formMenu.name[getKey]"
            :placeholder="$t('MenuTitlePlace')"
          />
        </a-col>
      </a-row>
      <a-row align="middle" class="flex-baseline">
        <a-col :span="6" class="form_title"> {{ $t("Alias") }}: </a-col>
        <a-col :span="12" class="sm-mb">
          <a-row align="middle" type="flex">
            <span class="mr-1 link" @click="copySlug">
              <a-icon :style="{ fontSize: '22px' }" type="copy" />
            </span>

            <span class="flex-1">
              <a-input id="slug-input" :value="formMenu.slug" :placeholder="$t('EnterAlias')"
                @input="onSlugChange" />
            </span>
          </a-row>
          <div v-if="!formMenu.slug" class="error-text">{{ $t("EnterAlias") }}:</div>
          <div v-if="errors.slug" class="z-error-text">
            {{ $t("NoAlias") }}:
          </div>
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button key="back" @click="modalVisible = false"> {{ $t('Close') }} </a-button>
        <a-button type="primary" @click="saveMenu">{{ $t('Save') }}</a-button>
      </template>
    </a-modal>
  </a-row>
</template>

<script>
// import searchInput from "@/components/wanted/search-input"

export default {
  data() {
    return {
      list: [],
      modalVisible: false,
      langTab: $langPrefix,
      formMenu: {
        name: { 
          uz: "",
          oz: "",
          ru: "",
          en: "",
        },
        type: 2,
        status: true,
        parent_id: 25,
        category: 'decisions',
        slug: ""
      },
      columns: [
        {
          title: this.$t("TagsColTitle"),
          key: "title",
          class: "wanted-fio-td",
          scopedSlots: {
            customRender: "name",
          },
        },
        {
          title: this.$t("postCount"),
          key: "count",
          fixed: "right",
          width: "100px",
          scopedSlots: { customRender: "count" },
        },
        {
          title: this.$t("UsersColTitle2"),
          key: "operation",
          fixed: "right",
          // width: "120px",
          scopedSlots: { customRender: "operation" },
        },
      ],
      errors: {
        slug: "",
      },
    }
  },
  computed: {
    getKey() {
      return this.langTab
    }
  },
  mounted() {
    this.$on("update:slug", this.onSlugChange);
    this.fetchData();
  },
  methods: {
    async fetchData() {
      // this.loading = true;
      try {
        const { data } = await this.$store.dispatch("menu/fetch", {
          type: 2,
          parent_id: 25,
          category: "decisions"
        });
        data && this.$set(this, "list", data);
        // this.loading = false;
      } catch (err) {
        console.log(err);
      }
      // this.loading = false;
    },
    async saveMenu() {
      if(this.formMenu.id){
        await this.$api.put(`/admin/menu/${this.formMenu.id}`, this.formMenu)
      }else{
        await this.$api.post("/admin/menu/create", this.formMenu)
      }
      this.modalVisible = false
      this.$router.go();
    },
    openCreateModal() {
      this.modalVisible = true
    },
    async editMenu(value) {
      const { data } = await this.$api.get(`/admin/menu/${value}`)
      this.formMenu.id = data && data.data && data.data.id
      this.formMenu.name = data && data.data && data.data.name
      this.formMenu.slug = data && data.data && data.data.slug
      this.formMenu.category = data && data.data && data.data.category
      this.formMenu.type = 2
      this.formMenu.parent_id = data && data.data && data.data.parent_id
      
      console.log(data.data)
      this.modalVisible = true
    },
    onSlugChange(e) {
      const text = (e.target.value || "")
        .replace(/( )/gi, "-")
        .toLowerCase()
        .replace(/[^a-zA-Z0-9\\-]/gi, "");

      this.slugCheck({ table: "menu", slug: text });

      this.formMenu.slug = text;
    },
    setSlugRes(arg) {
      this.errors.slug = arg ? "" : this.$t("NoAlias");
    },
    copySlug() {
      this.copyStringToClipboard(`/${this.$options.name}/${this.formMenu.slug}`);
      this.$message.success("ЧПУ скопирован");
    },
  },
};
</script>
<style>
.wanted-search-input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 430px;
  flex: 0 0 430px;
  justify-content: space-around;
}
.ministry .ant-table-pagination.ant-pagination {
  display: none;
}
</style>
